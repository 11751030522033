
import {
  IonButton,
  IonContent,
  IonPage,
  IonIcon,
  IonBadge,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from '@ionic/vue'
import { notificationsOutline } from 'ionicons/icons'
import { onMounted, ref } from 'vue'
import { getPrograms } from '@/api/api'
import { useStore } from '@/store'
import Header from '../../components/header/AppHeader.vue'
import Grid from '../../components/old/Grid.vue'
export default {
  name: 'ProgramList',
  components: {
    IonContent,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonButton,
    IonIcon,
    IonBadge,
    Header,
    Grid,
  },
  setup() {
    const { state } = useStore()
    const programs = ref()
    onMounted(() => {
      getPrograms()
        .then(res => (programs.value = res))
        .catch(e => console.error(e))
    })
    const doRefresh = (event: any) => {
      getPrograms()
        .then(res => {
          programs.value = res
          event.target.complete()
        })
        .catch(e => console.error(e))
    }
    return {
      notificationsOutline,
      programs,
      doRefresh,
      user: state.user,
      notifications: state.notifications,
    }
  },
}
