<template>
  <ion-page>
    <Header :title="$t('Programs')" back-link-header="true">
      <template #end>
        <ion-button color="white">
          <ion-icon slot="icon-only" :icon="notificationsOutline" />
          <ion-badge v-if="notifications.length !== 0">
            {{ notifications.length }}
          </ion-badge>
        </ion-button>
      </template>
    </Header>

    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content />
      </ion-refresher>
      <Grid
        :items="programs"
        :size="12"
        :large-size="12"
      />
      <ion-infinite-scroll threshold="100px" @ionInfinite="doRefresh($event)">
        <ion-infinite-scroll-content loading-spinner="circular" :loading-text="$t('Loading')" />
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonButton,
  IonContent,
  IonPage,
  IonIcon,
  IonBadge,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from '@ionic/vue'
import { notificationsOutline } from 'ionicons/icons'
import { onMounted, ref } from 'vue'
import { getPrograms } from '@/api/api'
import { useStore } from '@/store'
import Header from '../../components/header/AppHeader.vue'
import Grid from '../../components/old/Grid.vue'
export default {
  name: 'ProgramList',
  components: {
    IonContent,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonButton,
    IonIcon,
    IonBadge,
    Header,
    Grid,
  },
  setup() {
    const { state } = useStore()
    const programs = ref()
    onMounted(() => {
      getPrograms()
        .then(res => (programs.value = res))
        .catch(e => console.error(e))
    })
    const doRefresh = (event: any) => {
      getPrograms()
        .then(res => {
          programs.value = res
          event.target.complete()
        })
        .catch(e => console.error(e))
    }
    return {
      notificationsOutline,
      programs,
      doRefresh,
      user: state.user,
      notifications: state.notifications,
    }
  },
}
</script>

<style scoped></style>
